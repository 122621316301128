import {request,POST} from '../request.js'

/**
 * 获取全部小程序
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const getAllMiniPro = (data)=> {
    return POST('/merchant/minipro/getMerchantMiniProAll',data);
};

/**
 * 获取小程序信息
 * @param data
 * @returns {*}
 * @author TreeJohn
 */
export const getMiniProInfo = (data)=> {
    return POST('/merchant/minipro/getMiniProInfo',data);
};

/**
 * 获取我的小程序
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const getMyMiniPro = (data)=> {
    return POST('/merchant/minipro/getMyMiniPro',data);
};

/**
 * 创建小程序
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const addMiniPro = (data)=> {
    return POST('/merchant/minipro/addMiniPro',data);
};

/**
 * 获取商户人员列表
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const getAccountByMerchantIdAndMiniProId = (data)=> {
    return POST('/merchant/minipro/getAccountByMerchantIdAndMiniProId',data);
};

/**
 * 添加商户人员
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const addMembers = (data)=> {
    return POST('/merchant/minipro/addMembers',data);
};

/**
 * 删除商户人员
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const delMembers = (data)=> {
    return POST('/merchant/minipro/delMembers',data);
};