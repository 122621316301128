<template>
    <div class="cz-card"><slot/></div>
</template>

<script>
    export default {
        name: "cz-card"
    }
</script>

<style scoped lang="less">

    .cz-card{
        padding: 24px;
        background: white;
        border-radius: 8px;overflow: hidden;
        transition: .3s;
        &:hover{
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 4%);
        }
        //默认使用16px边框，建议统一使用此值，不然自定义class 覆盖他
        margin-bottom: 16px;
        box-sizing: border-box;
    }
</style>
