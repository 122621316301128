import {request,POST} from '../request.js'


/**
 * 检查授权状态
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const checkAuthorizeStatus = (data)=> {
    return request({
        method: 'get',
        url: '/wechat/authorize/checkAuthorizeStatus',
        params: data
    })
};

/**
 * 未授权时获取小程序信息
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const getMiniProInfo = (data)=> {
    return POST('/merchant/minipro/getMiniProInfo',data);
};

/**
 * 修改未授权时获取小程序信息
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const updateBaseMiniPro = (data)=> {
    return POST('/merchant/minipro/updateBaseMiniPro',data);
};


/**
 * 获取小程序密钥信息
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const getPaymentMerchantInfo = (data)=> {
    return POST('/merchant/minipro/getPaymentMerchantInfo',data);
};

/**
 * 更新小程序密钥信息
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const updatePaymentMerchantInfo = (data)=> {
    return POST('/merchant/minipro/updatePaymentMerchantInfo',data);
};

/**
 * 获取小程序基础信息
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const getAccountBasicInfo = (data)=> {
    return POST('/wechat/base/getAccountBasicInfo',data);
};

/**
 * 修改小程序头像
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const modifyHeadImage = (data)=> {
    return POST('/wechat/base/modifyHeadImage',data);
};

/**
 * 修改小程序介绍
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const modifySignature = (data)=> {
    return POST('/wechat/base/modifySignature',data);
};

/**
 * 获取账号已经设置的所有类目
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const getCategory = (data)=> {
    return POST('/wechat/category/getCategory',data);
};

/**
 * 提交审核并发布
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const commitMiniProCodeAndSubmitAudit = (data)=> {
    return POST('/wechat/code/manage/commitMiniProCodeAndSubmitAudit',data);
};

/**
 * 查询审核信息
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const getLatestAuditStatus = (data)=> {
    return POST('/wechat/code/audit/getLatestAuditStatus',data);
};

/**
 * 查询发布信息
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const releaseMiniProCode = (data)=> {
    return POST('/wechat/code/manage/releaseMiniProCode',data);
};

/**
 * 检查是否上传支付认证文件
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const verifyWeChatCertificationFile = (data)=> {
    return POST('/merchant/minipro/verifyWeChatCertificationFile',data);
};

/**
 * 获取微信代码模板列表
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const getTemplateDraftList = (data)=> {
    return POST('/wechat/admin/getTemplateDraftList',data);
};


/**
 * 将微信草稿模板添加到代码模板库
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const addToTemplate = (data)=> {
    return POST('/wechat/admin/addToTemplate',data);
};


/**
 * 初始化微信模板
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const initCodeTemplate = (data)=> {
    return POST('/wechat/admin/initCodeTemplate',data);
};