<template>
    <div class="flow-item">
        <div class="flow-item-title">{{title}}</div>
        <div class="flow-item-value">{{current}}</div>
        <div class="flow-item-proportion">
            <div class="flow-item-proportion-title">{{$t('m.dashboard.bijiao')}}</div>
            <img class="flow-item-proportion-icon" v-if="trend < 0" src="../../assets/manager-imgs/dashboard/jian_icon_after.png"/>
            <img class="flow-item-proportion-icon" v-else src="../../assets/manager-imgs/dashboard/jia_icon_after.png"/>
            <div :class="['flow-item-proportion-percentage',{red:trend > 0}]">{{percentage}}%</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "flow-item",
        props:{
            title:String,
            current:Number,
            prev:Number,
        },
        computed:{
            percentage(){
                if(this.prev === 0 && this.current === 0) return 0;
                if (this.prev === 0) return 100;
                if (this.current === 0) return 100;
                return Math.abs(Math.round((this.current - this.prev) / this.prev * 100));
            },
            trend(){
                if(this.current >= this.prev) return 1;
                return -1;
            },
        }
    }
</script>

<style scoped lang="less">
    @import "../../assets/style/index";

    .flow-item{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 120px;
        padding: 16px 0px;
        box-sizing: border-box;
        &-title{
            font-size: 12px;
            color: #999;
        }
        &-value{
            font-size: 18px;
            color: #333;
            font-weight: 600;
        }
        &-proportion{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: #999999;
            background: #F5F5F5;
            height: 20px;
            border-radius: @border-radius-sm;
            font-size: 12px;
            padding: 0 6px;
            &-title{

            }
            &-icon{
                height: 10px;
                width: 10px;
                margin: 0 2px;
            }
            &-percentage{
                color: #08bf5e;

                &.red{
                    color: red;
                }
            }
        }
    }
</style>
