import {POST} from "../request";


/**
 * 按月统计近一年的成交订单数/成交金额
 * @param data
 * @returns {*}
 * @author Mr.k
 */
export const statisticOrderByYear = (data)=> {
    return POST('/mall/statistic/orderByYear',data);
};

/**
 * 统计当月成交订单数/成交金额/较上月的涨幅
 * @param data
 * @returns {*}
 * @author Mr.k
 */
export const statisticOrderByMonth = (data)=> {
    return POST('/mall/statistic/orderByMonth',data);
};

/**
 * 按月统计近一年的访客数/浏览量/人均浏览量
 * @param data
 * @returns {*}
 * @author Mr.k
 */
export const statisticBrowserByYear = (data)=> {
    return POST('/consumer/statistic/browserByYear',data);
};

/**
 * 统计当月客数/浏览量/人均浏览量
 * @param data
 * @returns {*}
 * @author Mr.k
 */
export const statisticBrowserByMonth = (data)=> {
    return POST('/consumer/statistic/browserByMonth',data);
};
