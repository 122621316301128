<template>
    <div class="dashboard-body">
        <div class="dashboard-content">
            <cz-card style="margin-bottom: 30px;">
                <div class="flow-overview" >
                    <div class="msgTitle" style="display: flex;justify-content: space-between">
                        <div>{{$t('m.dashboard.liuliangzonglan')}}</div>
                        <el-form label-width="90px">
                            <el-form-item :label="$t('m.dashboard.tongjishijian')">
                                <el-date-picker
                                        v-model="month"
                                        type="month"
                                        size="small"
                                        :clearable="false"
                                        :editable="false"
                                        value-format="yyyy-MM"
                                        @change="monthChange"
                                        :picker-options="{disabledDate}"
                                        :placeholder="$t('m.dashboard.placeholder')">
                                </el-date-picker>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="flow-overview_content">
                        <div class="flow-overview_content_item">
                            <div class="flow-overview_content_item_title">{{$t('m.dashboard.liulanfangwen')}}</div>
                            <div class="flow-overview_content_item_type">
                                <FlowItem :title="$t('m.dashboard.fangkeliang')"
                                          :current="BrowserMonthDate.currentMonth.people"
                                          :prev="BrowserMonthDate.prevMonth.people"/>
                                <FlowItem :title="$t('m.dashboard.liulanliang')"
                                          :current="BrowserMonthDate.currentMonth.count"
                                          :prev="BrowserMonthDate.prevMonth.count"/>
                                <FlowItem :title="$t('m.dashboard.renyunliulan')"
                                          :current="BrowserMonthDate.currentMonth.countPeople"
                                          :prev="BrowserMonthDate.prevMonth.countPeople"/>
                            </div>
                        </div>
                        <div class="flow-overview_content_item">
                            <div class="flow-overview_content_item_title">{{$t('m.dashboard.chengjiaoshuju')}}</div>
                            <div class="flow-overview_content_item_type">
                                <FlowItem :title="$t('m.dashboard.chengjiaodingdanshu')"
                                          :current="OrderMonthDate.currentMonth.count"
                                          :prev="OrderMonthDate.prevMonth.count"/>
                                <FlowItem :title="$t('m.dashboard.chengjiaojine')"
                                          :current="OrderMonthDate.currentMonth.amount"
                                          :prev="OrderMonthDate.prevMonth.amount"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flow-trend">
                    <div class="msgTitle">{{$t('m.dashboard.liuliangqushi')}}</div>
                    <el-form label-width="90px">
                        <el-form-item :label="$t('m.dashboard.liulanfangwen')+'：'">
                            <el-radio-group v-model="type">
                                <el-radio :label="$t('m.dashboard.fangkeliang')" @change="buildChartDate(BrowserLineData,'people')">{{$t('m.dashboard.fangkeliang')}}</el-radio>
                                <el-radio :label="$t('m.dashboard.liulanliang')" @change="buildChartDate(BrowserLineData,'count')">{{$t('m.dashboard.liulanliang')}}</el-radio>
                                <el-radio :label="$t('m.dashboard.renyunliulan')" @change="buildChartDate(BrowserLineData,'countPeople')">{{$t('m.dashboard.renyunliulan')}}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item :label="$t('m.dashboard.chengjiaoshuju')+'：'">
                            <el-radio-group v-model="type">
                                <el-radio :label="$t('m.dashboard.chengjiaodingdanshu')" @change="buildChartDate(OrderLineData,'count')">{{$t('m.dashboard.chengjiaodingdanshu')}}</el-radio>
                                <el-radio :label="$t('m.dashboard.chengjiaojine')" @change="buildChartDate(OrderLineData,'amount')">{{$t('m.dashboard.chengjiaojine')}}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-form>

                    <div id="charts" style="height: 400px"/>
                </div>
            </cz-card>
        </div>
    </div>
</template>

<script>
    import CzCard from "../../components/common/cz-kit/cz-card";
    import HighCharts from 'highcharts'
    import {statisticOrderByMonth,
        statisticOrderByYear,
        statisticBrowserByYear,
        statisticBrowserByMonth} from "../../request/api/statistics";
    import {stringForDate} from "../../utils/DateUtil";
    import FlowItem from "./flow-item"
    import {getMiniProInfo} from "../../request/api/minipro";
    import {formatTime,getDaysBetween,getTimeStr} from "../../utils/DateUtil.js";
    import {getAccountBasicInfo,checkAuthorizeStatus} from "request/api/wechat.js"
    export default {
        name: "dashboard",
        components:{
            CzCard,
            HighCharts,
            FlowItem
        },
        data(){
            return{
                month:stringForDate(new Date(),'yyyy-MM'),
                type:this.$t('m.dashboard.fangkeliang'),
                MiniInfo:{},
                OrderLineData:[],
                BrowserLineData:[],
                OrderMonthDate:{
                    prevMonth:{},
                    currentMonth:{},
                },
                BrowserMonthDate:{
                    prevMonth:{},
                    currentMonth:{},
                },
                miniProId:"",
                authStates:0,
            }
        },
        computed:{
            expireDays(){
                let today = formatTime(new Date(),"yyyy-MM-dd HH:mm:ss");
                let expiredDate = formatTime(this.MiniInfo.expiredDate,"yyyy-MM-dd");
                let days = getDaysBetween(today,expiredDate)
                return getTimeStr(days);
            },
            isRecentExpired () {
                let today = formatTime(new Date(),"yyyy-MM-dd HH:mm:ss");
                let expiredDate = formatTime(this.MiniInfo.expiredDate,"yyyy-MM-dd");
                let days = getDaysBetween(today,expiredDate)
                return days[0] > 0 && days[0] < 31;
            },
        },
        mounted() {
            // this.getStatisticsByYear();
            // this.monthChange();
            // this.miniProId = this.$store.state.miniPro.miniProId
            // this.handleRefshAuth();
        },
        methods:{
            //判断小程序是否授权
            handleRefshAuth(){
                let _this = this
                checkAuthorizeStatus({
                    miniProId:_this.miniProId
                }).then(res=>{
                    console.log(res)
                    if(res.code==200){
                    //    已授权
                        _this.authStates = 1
                    }else{
                    //    未授权
                        _this.authStates = 0
                    }
                    _this.getMiniInfo()
                })
            },
            //获取小程序信息
            getMiniInfo(){
                let _this = this
                getMiniProInfo().then(res=>{
                    res.data.headerImgUrl = _this.$imageUrl+res.data.logoUrl
                    _this.MiniInfo = res.data;
                    if(this.authStates==1){
                        _this.MiniInfo.name = ""
                        _this.MiniInfo.headerImgUrl = ""
                        getAccountBasicInfo().then(resp=>{
                            if(res.code==200){
                                _this.MiniInfo.name = resp.data.nickname_info.nickname
                                _this.MiniInfo.headerImgUrl = resp.data.head_image_info.head_image_url
                            }
                        })
                    }
                })
            },
            //获取流量趋势
            getStatisticsByYear(){
                statisticOrderByYear().then(res=>{
                    this.OrderLineData = res.data;
                });
                statisticBrowserByYear().then(res=>{
                    this.BrowserLineData = res.data;
                    this.buildChartDate(this.BrowserLineData,'people');
                })
            },

            //获取流量总览
            monthChange(){
                statisticOrderByMonth({month:this.month}).then(res=>{
                    this.OrderMonthDate = res.data;
                });
                statisticBrowserByMonth({month:this.month}).then(res=>{
                    this.BrowserMonthDate = res.data;
                });
            },

            //获取图标数据
            buildChartDate(data,key){

                let ChartOptions = {
                    chart: {
                        marginTop: 70
                    },
                    title: { text: null },
                    yAxis: { title: { text: null} },
                    xAxis:{type:'category',},
                    legend: {
                        align: 'left',
                        verticalAlign: 'center',
                        x: 0,
                        y: 0,
                        lineHeight:40,
                        // useHTML: true,
                        // symbolWidth: 0.001,
                        // symbolHeight: 0.001,
                        // symbolRadius: 0.001,
                        // labelFormatter() {
                        //     let img = '\<\img src = "../../assets/manager-imgs/dashboard/chart_legend.png" width="14px" height="8px"\>'
                        //     return img + ' ' + this.name
                        // }
                    },
                    credits:{enabled:false},
                    series: [
                        {
                            name: this.type,
                            type:"spline",
                            color:"#1989fa",
                            data: data.map(d=>{return {y:d[key],name:d.date}})
                        }
                    ]
                }
                HighCharts.chart('charts',ChartOptions)
            },

            //去升级
            toUpgrade(){
                let query = {
                    miniProId:this.MiniInfo.id,
                    templateId:this.MiniInfo.templateId,
                };
                this.$router.push({
                    path:'/edit/create/payment',
                    query:query
                })
            },
            //去发布
            toPublish(){
                this.$router.push({
                    path:'/manager/shop/mini-pro/wechat'
                })
            },
            //去设计
            toDesign(){
                this.$router.replace("/making")
            },

            disabledDate(date){
                return date > new Date();
            }
        }
    }
</script>

<style scoped lang="less">
    @import "../../assets/style/index";
    ::-webkit-scrollbar{
        display: none;
    }
    .dashboard-body{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        .expire-time-wrapper{
            height: 80px;
            background: #fffbe6;
            border:1px solid #ffe58f;
            margin: -16px -24px 24px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0 24px;
            box-sizing: border-box;
            .tips{
                flex: 1;
                display: flex;
                flex-direction: column;
                .title{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    font-weight: @font-weight-medium;
                    color: black;
                    font-size: 16px;
                    .icon{
                        margin-right: 20px;
                    }
                }
                .desc{
                    margin-left: 44px;
                    font-size: 14px;
                    margin-top: 2px;
                }
            }
            .buy-button{
                width: 80px;
                height: 32px;
                .flex-center;
                font-weight: normal;
            }
        }
    }
    .dashboard-content{
        width: 100%;
        flex: 1;
        overflow: scroll;
    }
    .header{
        display: flex;
        justify-content: space-between;
        .header-left{
            display: flex;
            flex-direction: column;
            .mini-info{
                display: flex;
                flex-direction: row;
                align-items: center;
                .header-url{
                    flex-shrink: 0;
                    width: 48px;
                    height: 48px;
                    border-radius: 50%;
                    border: 1px solid #eee;
                    margin-right: 16px;
                }
                .header-logourl{
                    flex-shrink: 0;
                    width: 48px;
                    height: 48px;
                    border-radius: 50%;
                    margin-right: 16px;
                    background: #EFEFEF;
                    box-sizing: border-box;
                    padding-top: 12px;
                    text-align: center;
                    img{
                        width: 27px;
                        height: 25px;
                    }
                }
                .header-name{
                    .header-msg_name{
                        font-size: 14px;
                        font-weight: 600;
                        color: #333333;
                    }
                    .header-msg_tips{
                        font-size: 12px;
                        font-weight: 400;
                        color: #999999;
                        margin-top: 5px;
                    }
                }
            }
            .header-msg_shop{
                display: flex;
                margin-top: 29px;
                align-items: center;
                .header-msg_shopbtn{
                    width: 88px;
                    height: 24px;
                    line-height: 24px;
                    text-align: center;
                    border-radius: 4px;
                    border: 1px solid #E7E7E7;
                    margin-right: 16px;
                    cursor: pointer;
                }
            }
        }

        .header-card{
            display: flex;
            .header-card-item{
                width: 211px;
                height: 96px;

                border-radius: 8px;
                margin-left: 24px;
                position: relative;
                &:nth-of-type(1){
                    background: linear-gradient(145deg, #FF6F33 0%, #FB5915 100%);
                }
                &:nth-of-type(2){
                    background: linear-gradient(145deg, #49CE99 0%, #2FBD85 100%);
                }
                &:hover{
                    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
                }
                .header-card-item_bg{
                    width: 211px;
                    height: 96px;
                }
                .header-card-item_content{
                    width: 163px;
                    height: 56px;
                    padding: 20px 24px;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
                .header-card-item_title{
                    font-size: 14px;
                    font-weight: 600;
                    color: #FFFFFF;
                }
                .header-card-item_btns{
                    margin-top: 10px;
                    span{
                        display: inline-block;
                        width: 64px;
                        height: 24px;
                        line-height: 24px;
                        text-align: center;
                        border-radius: 4px;
                        border: 1px solid rgba(255, 255, 255, 0.25);
                        font-size: 12px;
                        font-weight: 400;
                        color: #FFFFFF;
                        margin-right: 10px;
                        cursor: pointer;
                    }
                }
            }

        }
    }
    .flow-overview{
        border-bottom: 1px solid #EEEEEE;
        padding-bottom: 32px;
        .flow-overview_content_item{

            &:nth-of-type(1){
                width:59%;
            }
            &:nth-of-type(2){
                width:39%;
            }

            .flow-overview_content_item_title{
                width: 100%;
                height: 32px;
                line-height: 32px;
                text-align: center;
                background: rgba(15, 187, 127, 0.05);
                border-radius: 8px;
                font-size: 14px;
                font-weight: 600;
                color: @color-primary;
            }
        }
        .flow-overview_content{
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
        }
        .flow-overview_content_item_type{
            display: flex;
            justify-content: space-around;
            text-align: center;
        }
    }
    .flow-trend{
        margin-top: 10px;
    }
    .msgTitle{
        font-size: 14px;
        font-weight: 600;
        color: #333333;
        line-height: 32px;
    }
    /deep/ .el-form-item{
        margin-bottom: 0px!important;
    }
</style>
